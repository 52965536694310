export const environment = {
  production: true,
  loginUrl: 'https://login.enablement-app.com',
  graphQLURL: 'https://api.enablement-app.com/graphql',
  coachingAppUrl: 'https://coaching.enablement-app.com',
  learningPortalUrl: 'https://learning.enablement-app.com',
  assessmentAppUrl: 'https://assessment.enablement-app.com',
  skillMapAppUrl: 'https://skill-map.enablement-app.com',
  dashboardAppUrl: 'https://dashboard.enablement-app.com',
  adminAppUrl: 'https://admin.enablement-app.com',
  firebase: {
    ap1: {
      apiKey: 'AIzaSyATdS4VOioLVVxQvVvzLVbpUoc05WZWCEY',
      authDomain: 'enablement-app-prod-ap1.firebaseapp.com',
      databaseURL:
        'https://enablement-app-prod-ap1-default-rtdb.firebaseio.com',
      projectId: 'enablement-app-prod-ap1',
      storageBucket: 'enablement-app-prod-ap1.appspot.com',
      messagingSenderId: '615669831048',
      appId: '1:615669831048:web:90a5b835bc79431729563d',
    },
    us1: {
      apiKey: 'AIzaSyA947E7udIaHjOYtz-3v2tALmycUwo4WGo',
      authDomain: 'enablement-app-prod-us1.firebaseapp.com',
      databaseURL:
        'https://enablement-app-prod-us1-default-rtdb.firebaseio.com',
      projectId: 'enablement-app-prod-us1',
      storageBucket: 'enablement-app-prod-us1.appspot.com',
      messagingSenderId: '265868057839',
      appId: '1:265868057839:web:4ca21f04b3d8fafb9fcf1a',
    },
  },
}
